@import '~styles/variables';
@import '~styles/base';

.search {
    p:last-of-type {
        font-size: 1.6rem;
    }

    .loading-carousel {
        background-color: red;
        height: 140px;
    }

    &.mobile {
        h1 {
            text-align: center;
        }

        > div:last-of-type {
            margin: auto;
        }
    }
}

.search-results {
    display: flex;
    flex: 1;
    flex-direction: column;

    > div:first-of-type {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin: auto;
        max-width: 1500px;
        width: 90%;

        h2 {
            color: $white;
            font-size: 3.2rem;
            padding-left: 20px;
        }

        .results {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            margin: 20px 0;
        }

        .get-in-touch {
            align-items: center;
            color: $white;
            display: flex;
            flex-direction: column;
            height: 175px;
            justify-content: center;

            h3 {
                color: $green;
                font-size: 3.2rem;
                margin-bottom: 5px;
            }

            p {
                font-size: 1.6rem;

                a {
                    color: $white;
                    text-decoration: none;
                    user-select: text;
                }
            }
        }
    }
}
