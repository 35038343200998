@import '~styles/variables';

.overlay {
    background-color: rgba($navy, 0.7);
    height: 100%;
    position: fixed;
    top: 80px;
    transform: translateY(-150%);
    transition: transform 0.5s ease-in-out;
    width: 100vw;
    z-index: 2;

    &.visible {
        transform: translateY(0);
    }

    li {
        background-color: $white;
        border-top: 1px solid $border;
        cursor: pointer;
        font-size: 2rem;
        height: 100px;

        a {
            align-items: center;
            color: $grey;
            display: flex;
            font-weight: 500;
            height: 100%;
            justify-content: center;
            text-decoration: none;
        }
    }
}

nav {
    align-items: center;
    display: flex;
    font-size: 2rem;
    padding: 30px 50px;
    z-index: 2;

    &.mobile {
        background-color: $white;
        height: 80px;
        justify-content: center;
        padding: 0 25px;

        .search,
        .menu {
            cursor: pointer;
            height: 20px;
            width: 20px;
        }

        .logo {
            display: flex;
            flex: 1;
            justify-content: center;
        }

        div:first-of-type img {
            height: 35px;
        }
    }

    div:first-of-type {
        flex: 1;

        img {
            height: 55px;
        }
    }

    ul {
        display: flex;
        list-style-type: none;

        li {
            margin-left: 40px;
            text-decoration: none;

            a {
                color: $white;
                font-weight: 500;
                padding: 10px 0;
                text-decoration: none;

                &.active {
                    border-bottom: 2px solid $green;
                    color: $green;
                }
            }
        }
    }
}
