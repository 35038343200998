@import '~styles/variables';

.drugs-carousel {
    margin-top: 20px;
    width: 920px;

    .drug {
        background-color: $white;
        border-radius: 10px;
        box-shadow: 0 0 10px #575454;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        text-decoration: none;
        width: 140px;

        > img {
            height: 100px;
            object-fit: cover;
        }

        > div {
            align-items: center;
            color: $purple;
            display: flex;
            height: 40px;
            margin: 0 15px;
            white-space: nowrap;
            width: 145px;

            > div {
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

/* stylelint-disable */
:global {
    .BrainhubCarousel__arrowLeft {
        margin-right: 25px;
    }

    .BrainhubCarousel__arrowRight {
        margin-left: 25px;
    }

    .BrainhubCarousel__arrowLeft,
    .BrainhubCarousel__arrowRight {
        background-color: $purple;
        border-radius: 10px;
    }

    .BrainhubCarousel__arrows:hover:enabled {
        background-color: $purple;
    }
}
/* stylelint-enable */
