@import '~styles/variables';

.result-row {
    align-items: center;
    background-color: $white;
    border-top: 1px solid $border;
    color: $navy;
    display: flex;
    min-height: 50px;
    padding: 10px 0;
    width: 100%;

    &:last-of-type {
        border-radius: 0 0 10px 10px;
    }

    > div {
        overflow: hidden;
        padding-left: 20px;
        width: 20%;

        a {
            color: $purple;
            text-decoration: none;
        }
    }
}
