@import '~styles/variables';

.search-form {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 0 10px #575454;
    margin-top: 30px;
    overflow: hidden;
    width: 770px;

    &.loading {
        align-items: center;
        display: flex;
        height: 268px;
        justify-content: center;
    }

    &.mobile {
        margin: 20px auto;
        width: 90%;

        &.loading {
            height: 318px;
        }

        form {
            flex-direction: column;

            .button {
                color: $white;
                height: 50px;
                width: 100%;
            }
        }
    }

    &.horizontal {
        height: 66px;
        margin-top: 30px;
        width: 100%;

        form {
            .form {
                flex: 1;
                flex-direction: row;
            }

            .button {
                background-color: $lavender;
                flex: 0 0 66px;
            }
        }
    }

    form {
        display: flex;
        height: 100%;
        width: 100%;

        .form {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 3px 20px;
        }

        .button {
            align-items: center;
            background-color: $lavender;
            cursor: pointer;
            display: flex;
            justify-content: center;
            width: 80px;

            svg {
                height: 32px;
                width: 32px;
            }
        }
    }
}
