@import '~styles/variables';

.search-results {
    flex: 1;

    .results-header {
        align-items: center;
        background-color: $green;
        border-radius: 10px 10px 0 0;
        color: $white;
        display: flex;
        height: 50px;
        width: 100%;

        > div {
            flex: 1;
            padding-left: 20px;
        }
    }
}

.search-results-mobile {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 90%;
}

:global {
    /* stylelint-disable-next-line */
    .MuiCircularProgress-circle {
        color: $purple;
    }
}

.loading {
    display: flex;
    justify-content: center;

    &.mobile {
        flex: 1;
        margin-top: 10px;
    }
}
