@import '~styles/variables';

.container {    
    background-image: url('/images/background.png');
    background-attachment: fixed;
    background-color: $background;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: $white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    main {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
}
