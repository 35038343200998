@import '~styles/variables';

.contact-form {
  height: 100%;
  margin-top: 3rem;

  button {
    align-items: center;
    background-color: $green;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
    padding:1rem 0;
    font-size:3rem;
    color: $white;
    border:0;
    border-radius: 1rem;
  }

}

.input-row {
  align-items: center;
  height: 100%;
  margin-bottom: 2rem;

  label {
    display: block;
  }

  input, textarea {
    border: 0;
    border-bottom: 1px solid $border;
    color: $purple;
    flex: 1;
    font-size: 1.6rem;
    height: 36px;
    margin: 10px 0;
    width: 60rem;
    padding: 3px 6px;

    &:focus {
      color: $purple;
      outline: none;
    }
  }

  textarea {
    height: 16rem;
  }
}
