.center {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  &.mobile {
    h1 {
      padding-top: 30px;
    }

    p {
      font-size: 1.6rem;
      margin-top: 20px;
      padding: 0 20px;
    }
  }

  h1 {
    color: $white;
    font-size: 3.2rem;
    font-weight: 600;
  }

  p {
    color: $white;
    font-size: 2rem;
    line-height: 1.4;
    margin-top: 20px;
    max-width: 760px;
    text-align: center;
  }
}