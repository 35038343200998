@import '~styles/variables';

.feed {
    background-color: $green;
    margin-top: 60px;
    min-height: 243px;
    width: 100%;

    .content {
        margin: 30px auto;
        min-width: 800px;
        width: 80%;
        max-width: 1600px;

        @media only screen and (max-width: 1300px) {
            width: 95%;
        }

        .header {
            display: flex; 
            align-items: center;
            margin-bottom: 20px;

            h3 {
                font-size: 2rem;
                font-weight: normal;
                margin-left: 15px;
            }
        }

        .items {
            display: flex;
            justify-content: space-between;

            > a {
                background-color: $white;
                border-radius: 8px;
                box-shadow: 0 0 5px #a9a6a6;
                min-height: 110px;
                padding: 15px 20px;
                text-decoration: none;
                width: 15%;

                > div:first-of-type {
                    color: $grey;
                    font-size: 1.4rem;
                }

                > div:last-of-type {
                    color: $navy;
                    font-weight: 500;
                    margin-top: 15px;
                }
            }
        }
    }
}