@import '~styles/variables';

.input-row {
    align-items: center;
    display: flex;
    //height: 100%;

    &.horizontal-form {
        flex: 1;
        margin-right: 20px;

        @media only screen and (max-width: 1250px) {
            margin-right: 5px;

            input {
                font-size: 1.4rem;
            }

            svg {
                margin-right: 5px;
            }
        }
    }

    &.edited {
        input {
            border-bottom: 1px solid $purple;
        }
    }

    input {
        border: 0;
        border-bottom: 1px solid $border;
        color: $purple;
        flex: 1;
        font-size: 1.6rem;
        height: 36px;
        margin: 10px 0;

        &:focus {
            color: $purple;
            outline: none;
        }
    }

    svg {
        height: 36px;
        margin-right: 20px;
        width: 36px;
    }
}

:global {
    /* stylelint-disable-next-line */
    .MuiAutocomplete-popper {
        li {
            color: $purple;
            font-size: 1.6rem;
        }
    }
}
