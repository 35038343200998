@import '~styles/variables';

.result {
    background-color: $white;
    border-radius: 10px;
    color: $navy;
    margin: 10px 0;
    width: 100%;

    &:first-of-type {
        margin-top: 20px;
    }

    &:last-of-type {
        margin-bottom: 40px;
    }

    > div {
        margin: 0 20px;
        overflow: hidden;
        padding: 20px 0;

        &:not(:first-of-type) {
            border-top: 1px solid $border;
        }

        a {
            color: $purple;
        }
    }
}
