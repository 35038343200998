@import '~styles/variables';
@import '~styles/base';

.about {

  div.faded {
    img {
      opacity: 0;
      z-index: 0;
    }

    iframe {
      opacity: 1;
      z-index: 1;
    }
  }

  div.not-faded {
    img {
      opacity: 1;
      z-index: 1;
    }

    iframe {
      opacity: 0;
      z-index: 0;
    }
  }

  .vidFrame {

    position: relative;
    height: 400px;

    iframe {
      margin-top:3rem;
      margin-bottom: 3rem;
      position: absolute;
      left: 50%;
      margin-left: -320px;
      top: 50%;
      margin-top: -180px;
    }

    img {
      position: absolute;
      cursor: pointer;
      margin-top: 3rem;
      margin-bottom: 3rem;
      left: 50%;
      margin-left: -320px;
      top: 50%;
      margin-top: -180px;
    }

  }
}