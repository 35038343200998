@import '~styles/variables';

footer {
    align-items: center;
    color: #8f919c;
    display: flex;
    font-size: 1.6rem;
    justify-content: space-between;
    padding: 50px;

    .links {
        display: flex;

        > div {
            margin-right: 40px;
        }

        a {
            color: $grey;
        }
    }

    .social {
        display: flex;

        > div {
            align-items: center;
            display: flex;
            margin-left: 40px;
            text-align: center;

            svg {
                margin-right: 10px;
                width: 32px;
            }
        }
    }
}
