@import '~styles/variables';

.latest-approvals {
  line-height: 1.4;
  padding-top: 20px;
  text-align: center;
  max-width: 600px;

  a {
    text-decoration: none;
    color: $green;

    &:hover {
      color: $lavender;
    }
  }
}